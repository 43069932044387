import React, { useEffect, useState } from 'react';

import { isArray } from 'lodash';
import PropTypes from 'prop-types';

import ProgressBar from './ProgressBar';
import ArrowIcon from '../../../assets/icons/arrowIcon.png';
import EmptyStateImage from '../../../assets/nodata.png';
import './ProgressDiv.css';
import AnalyticsSpinner from '../AnalyticsSpinner';

const PAGE_SIZE = 5;

function ProgressDiv({ title, subtitle, values, color, onClick, isLoading }) {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentValues, setCurrentValues] = useState([]);

  const getCurrentValues = () => {
    const vals = values.slice(currentPageNumber * PAGE_SIZE, (currentPageNumber + 1) * PAGE_SIZE);
    setCurrentValues(vals);
  };

  const initialise = () => {
    const numberOfPages = Math.ceil(values.length / PAGE_SIZE);
    setNumberOfPages(numberOfPages);
    setCurrentPageNumber(0);
    getCurrentValues();
  };

  const prevPage = (event) => {
    event.stopPropagation();
    if (currentPageNumber === 0) return;
    setCurrentPageNumber((prev) => prev - 1);
  };

  const nextPage = (event) => {
    event.stopPropagation();
    if (currentPageNumber + 1 >= numberOfPages) return;
    setCurrentPageNumber((prev) => prev + 1);
  };

  const getPaginationText = () => {
    if (!values || !isArray(values) || values.length === 0) return `0 of 0 results`;
    if (values && isArray(values) && values.length === 1) return `1 of 1 results`;

    const startValue = currentPageNumber * PAGE_SIZE + 1;
    const endValue = (currentPageNumber + 1) * PAGE_SIZE;

    return `${startValue} - ${endValue > values.length ? values.length : endValue} of ${
      values.length
    } results`;
  };

  useEffect(() => {
    initialise();
  }, [values]);

  useEffect(() => {
    getCurrentValues();
  }, [currentPageNumber]);

  return (
    <div className="stepwise-analytics-stats-div">
      <h4>{title}</h4>
      <p>{subtitle}</p>
      {isLoading ? (
        <AnalyticsSpinner />
      ) : (
        <>
          {currentValues && currentValues.length > 0 ? (
            currentValues.map((item) => {
              return (
                <ProgressBar
                  key={item.id || item.label}
                  elementId={item.id}
                  onClick={onClick}
                  label={item.label}
                  value={item.value}
                  width={item.width}
                  color={color}
                />
              );
            })
          ) : (
            <div className="progress-div-no-data-div">
              <img src={EmptyStateImage} alt="No data to be displayed" />
              <p>There is not enough data to provide these insights right now</p>
            </div>
          )}
          <div className="analytics-stats-div-footer">
            <p>
              <button
                type="button"
                tabIndex={currentPageNumber - 1}
                onClick={prevPage}
                disabled={currentPageNumber === 0}
                className="analytics-stats-pagination-left">
                <img src={ArrowIcon} alt="Left Icon" />
              </button>
              {getPaginationText()}
              <button
                type="button"
                onClick={nextPage}
                disabled={currentPageNumber + 1 >= numberOfPages}
                className="analytics-stats-pagination-right">
                <img src={ArrowIcon} alt="Right Icon" />
              </button>
            </p>
          </div>
        </>
      )}
    </div>
  );
}

ProgressDiv.defaultProps = {
  isLoading: false,
  values: []
};

ProgressDiv.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  values: PropTypes.array,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default ProgressDiv;
