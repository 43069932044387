import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TextHoverShowcase } from 'storybook-ui-components';

import '../ProgressDiv.css';

function ProgressBar({ label, value, width, color, onClick, elementId }) {
  return (
    <div
      aria-hidden
      onClick={(event) => {
        onClick(event, elementId, label);
      }}
      className="progress-container">
      <Row>
        <Col xl={8} className="label-col">
          <TextHoverShowcase className="progress-label-text" text={label} />
        </Col>
        <Col className="progress-col">
          <div
            style={{ width: `${width}%`, background: `${color}` }}
            className="stats-progress-bar">
            <p>{value}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

ProgressBar.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ProgressBar;
